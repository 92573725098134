import queryString from 'query-string';

export const parseCode = () => {
  const { code, c } = queryString.parse(window.location.search);

  if (!code && !c) {
    return Promise.reject(new Error('Missing query params'));
  }

  return Promise.resolve({ code: (code || c) as string });
};
