import React from 'react';
import styled from '@emotion/styled';
import { Spinner as CircuitSpinner } from '@sumup/circuit-ui';

const SpinnerContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  align-self: center;
`;

const Spinner = () => (
  <SpinnerContainer>
    <CircuitSpinner />
  </SpinnerContainer>
);

export default Spinner;
