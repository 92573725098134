import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Anchor } from '@sumup/circuit-ui';
import { SumUpLogo } from '@sumup/icons';

import { LINKS } from 'constants/global';

const StyledAnchor = styled(Anchor)(
  ({ theme }) => css`
    display: block;
    flex: none;
    align-self: start;
    width: 110px;
    margin-bottom: ${theme.spacings.tera};
  `,
);

const StyledLogo = styled(SumUpLogo)`
  display: block;
  color: var(--cui-fg-normal);
`;

const Logo = () => {
  const { t, i18n } = useTranslation();

  const { website } = LINKS[i18n.language] || LINKS.default;

  return (
    <StyledAnchor
      href={website}
      target="_blank"
      rel="noopener"
      aria-label={t('common.website_link')}
    >
      <StyledLogo role="presentation" />
    </StyledAnchor>
  );
};

export default Logo;
