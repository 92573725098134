import React, { lazy, Suspense } from 'react';
import {
  RouterProvider,
  Route,
  LoaderFunction,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getLocalizedRoute } from './RoutesService';

import { ROUTES } from 'constants/global';
import Spinner from 'components/Spinner';
import { locales } from 'utils/i18n';

const Unsubscribe = lazy(
  () => import(/* webpackChunkName: "page-unsubscribe" */ 'pages/Unsubscribe'),
);

const Settings = lazy(
  () => import(/* webpackChunkName: "page-settings" */ 'pages/Settings'),
);

const ChangeSubscription = lazy(
  () =>
    import(
      /* webpackChunkName: "page-change-subscription" */ 'pages/ChangeSubscription'
    ),
);

const Subscribe = lazy(
  () => import(/* webpackChunkName: "page-subscribe" */ 'pages/Subscribe'),
);

const Subscribed = lazy(
  () => import(/* webpackChunkName: "page-subscribed" */ 'pages/Subscribed'),
);

const Error = lazy(
  () => import(/* webpackChunkName: "page-error" */ 'pages/Error'),
);

const Routes = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const localePrefix = '/:locale';

  const localeRedirect: LoaderFunction = ({ params, request }) => {
    const { pathname, search } = new URL(request.url);
    const validLocale = locales.find(
      (locale) =>
        locale.toLocaleLowerCase() === params.locale?.toLocaleLowerCase(),
    );
    if (!validLocale) {
      return redirect(
        getLocalizedRoute(
          `${pathname.replace(`/${params.locale}`, '')}${search}`,
          language,
        ),
      );
    }
    return null;
  };

  const pathRedirect: LoaderFunction = ({ request }) => {
    const { pathname, search } = new URL(request.url);
    const validPath = Object.values(ROUTES).find((route) => route === pathname);
    if (validPath) {
      return redirect(getLocalizedRoute(`${pathname}${search}`, language));
    }
    return redirect(getLocalizedRoute(ROUTES.ERROR, language));
  };

  const settingsRedirect: LoaderFunction = ({ params, request }) => {
    const badLocaleRedirect = localeRedirect({ params, request });
    if (badLocaleRedirect) {
      return badLocaleRedirect;
    }
    const { search } = new URL(request.url);
    return redirect(getLocalizedRoute(`${ROUTES.SETTINGS}${search}`, language));
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path={localePrefix + ROUTES.CHANGE_EMAIL} // Backward compatibility for old email links
          loader={settingsRedirect}
        />
        <Route
          path={localePrefix + ROUTES.SETTINGS_SHORT} // Shortened settings link for SMS receipts
          loader={settingsRedirect}
        />
        <Route
          path={localePrefix + ROUTES.UNSUBSCRIBE}
          loader={localeRedirect}
          element={<Unsubscribe />}
        />
        <Route
          path={localePrefix + ROUTES.SETTINGS}
          loader={localeRedirect}
          element={<Settings />}
        />
        <Route
          path={localePrefix + ROUTES.CHANGE_SUBSCRIPTION}
          loader={localeRedirect}
          element={<ChangeSubscription />}
        />
        <Route
          path={localePrefix + ROUTES.SUBSCRIBE}
          loader={localeRedirect}
          element={<Subscribe />}
        />
        <Route
          path={localePrefix + ROUTES.SUBSCRIBED}
          loader={localeRedirect}
          element={<Subscribed />}
        />
        <Route
          path={localePrefix + ROUTES.ERROR}
          loader={localeRedirect}
          element={<Error />}
        />
        <Route path="*" loader={pathRedirect} />
      </>,
    ),
  );

  return (
    <Suspense fallback={<Spinner />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default Routes;
