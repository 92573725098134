import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import env from 'utils/env';
import { cookieConsentEvents } from 'utils/cookie-consent';
import { parseCode } from 'utils/parseQuery';

Sentry.init({
  dsn: env('SENTRY_DSN'),
  environment: env('ENV'),
  release: process.env.RELEASE_VERSION,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: env('ENV') === 'production' ? 0.01 : 1.0,
  maxBreadcrumbs: 50,

  // Common errors and URLs to filter, stripped down version adapted from
  // https://gist.github.com/impressiver/5092952
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
  ],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Firefox extensions
    /^moz-extension:\/\//i,
    /^resource:\/\//i,
  ],
});

function setUser({ categories }: { categories: string[] }) {
  if (categories.includes('PERFORMANCE')) {
    parseCode()
      .then(({ code }) => Sentry.setUser({ id: code }))
      .catch(() => {});
  } else {
    Sentry.configureScope((scope) => scope.setUser(null));
  }
}

cookieConsentEvents.on('init', setUser);
cookieConsentEvents.on('change', setUser);
