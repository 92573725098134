import React, { Suspense } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Logo from './components/Logo';
import { LayoutProps } from './types';

import { MOBILE_BREAKPOINT } from 'constants/global';
import Spinner from 'components/Spinner';

const LayoutContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding: ${theme.spacings.giga} ${theme.spacings.tera};

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      align-items: flex-start;
      padding: ${theme.spacings.tera} ${theme.spacings.mega};
    }
  `,
);

const Layout = ({ children }: LayoutProps) => (
  <LayoutContainer>
    <Suspense fallback={<Spinner />}>
      <Logo />
      {children}
    </Suspense>
  </LayoutContainer>
);

export default Layout;
