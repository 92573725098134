// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../tmp/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../tmp/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../tmp/node_modules/css-loader/dist/cjs.js!../../tmp/node_modules/@sumup/design-tokens/light.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../tmp/node_modules/css-loader/dist/cjs.js!../../tmp/node_modules/@sumup/circuit-ui/dist/style.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
`, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
