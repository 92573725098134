import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export const locales = [
  'en-GB',
  'en-IE',
  'en-US',
  'es-ES',
  'es-CL',
  'es-US',
  'fr-FR',
  'it-IT',
];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: locales,
    fallbackLng: 'en-GB',
    load: 'currentOnly',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: '/locales/{{lng}}.json',
    },
    react: {
      useSuspense: true, // only render children after loading is complete
    },
    detection: {
      order: ['path', 'navigator'],
      lookupFromPathIndex: 0,
    },
  })
  .catch((e) => console.log(e));

i18n.on('languageChanged', (language) => {
  document.documentElement.setAttribute('lang', language);
});

export default i18n;
