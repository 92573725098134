export const MOBILE_BREAKPOINT = 375;

export const COOKIE_CONSENT_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'CH',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
] as const;

export const LINKS: {
  [language: string]: {
    contact: string;
    faq: string;
    privacy: string;
    website: string;
  };
} = {
  'default': {
    contact: 'https://sumup.co.uk/contact/',
    faq: 'https://help.sumup.com/en-GB/articles/7i2aRZAx5u6u9qnGKnToJP',
    privacy: 'https://sumup.co.uk/privacy/',
    website: 'https://sumup.co.uk/',
  },
  'en-IE': {
    contact: 'https://sumup.ie/contact/',
    faq: 'https://help.sumup.com/en-IE/articles/7i2aRZAx5u6u9qnGKnToJP',
    privacy: 'https://sumup.ie/privacy/',
    website: 'https://sumup.ie/',
  },
  'en-US': {
    contact: 'https://sumup.com/contact-us/',
    faq: 'https://help.sumup.com/en-US/articles/7i2aRZAx5u6u9qnGKnToJP',
    privacy: 'https://sumup.com/privacy/',
    website: 'https://sumup.com/',
  },
  'es-ES': {
    contact: 'https://sumup.es/contacto/',
    faq: 'https://help.sumup.com/es-ES/articles/7i2aRZAx5u6u9qnGKnToJP',
    privacy: 'https://sumup.es/aviso-de-privacidad/',
    website: 'https://sumup.es/',
  },
  'es-CL': {
    contact: 'https://sumup.cl/contacto/',
    faq: 'https://help.sumup.com/es-CL/articles/7i2aRZAx5u6u9qnGKnToJP',
    privacy: 'https://sumup.cl/aviso-de-privacidad/',
    website: 'https://sumup.cl/',
  },
  'es-US': {
    contact: 'https://es.sumup.com/contact-us/',
    faq: 'https://help.sumup.com/es-US/articles/7i2aRZAx5u6u9qnGKnToJP',
    privacy: 'https://es.sumup.com/privacy/',
    website: 'https://es.sumup.com/',
  },
  'fr-FR': {
    contact: 'https://sumup.fr/contact/',
    faq: 'https://help.sumup.com/fr-FR/articles/7i2aRZAx5u6u9qnGKnToJP',
    privacy: 'https://sumup.fr/protection-des-donnees/',
    website: 'https://sumup.fr/',
  },
  'it-IT': {
    contact: 'https://sumup.it/contatti/',
    faq: 'https://help.sumup.com/it-IT/articles/7i2aRZAx5u6u9qnGKnToJP',
    privacy: 'https://sumup.it/tutela-privacy/',
    website: 'https://sumup.it/',
  },
};

export const ROUTES = {
  UNSUBSCRIBE: '/unsubscribe',
  CHANGE_EMAIL: '/change-email',
  CHANGE_SUBSCRIPTION: '/change-subscription',
  SETTINGS: '/settings',
  SETTINGS_SHORT: '/s',
  SUBSCRIBE: '/subscribe',
  SUBSCRIBED: '/subscribed',
  ERROR: '/error',
} as const;
