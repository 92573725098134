import mitt from 'mitt';
import Cookies from 'js-cookie';

import { COOKIE_CONSENT_COUNTRIES } from 'constants/global';
import env from 'utils/env';
import i18n from 'utils/i18n';

const ONETRUST_CONSENT_COOKIE = 'OptanonConsent';

// Mapping OneTrust's internal consent category ids to their human readable names.
const CONSENT_CATEGORIES = {
  C0001: 'STRICTLY_NECESSARY',
  C0002: 'PERFORMANCE',
  C0003: 'FUNCTIONAL',
  C0004: 'TARGETING',
  C0005: 'SOCIAL_MEDIA',
};

const changeLanguage = (language) => {
  // FIXME: OneTrust does not support the German Luxembourg (de-LU) locale.
  // Their support suggested to use the Dutch Luxembourg locale (nl-LU)
  // to store the translations until they fix it.
  // Track solution progress here: https://ideas.onetrust.com/ideas/OT-I-11995
  // Once the issue has been solved we need to remove this logic.
  if (language === 'de-LU') {
    window.OneTrust.changeLanguage('nl-LU');
  } else {
    window.OneTrust.changeLanguage(language);
  }
};

export const DEFAULT_CONSENT_CATEGORIES = ['STRICTLY_NECESSARY'];

// This custom event emitter acts as a bridge between the OneTrust SDK and the
// exported consent API. This abstraction layer will make it easier to switch
// to a different provider in the future.
// Furthermore, it is necessary to prevent memory leaks when subscribing to
// events from React since the OneTrust SDK doesn't offer a way to unsubscribe.
export const cookieConsentEvents = mitt();

/**
 * Attempts to open the cookie preference modal which also contains the link
 * to the cookie policy.
 */
export const showCookieConsentPreferences = () => {
  if (!window.OneTrust) {
    return;
  }
  window.OneTrust.ToggleInfoDisplay();
};

/**
 * Reads and parses the OneTrust cookie that contains the currently active
 * cookie consent category ids and returns them as human readable names.
 */
export const getActiveConsentCategories = () => {
  const consentCookie = Cookies.get(ONETRUST_CONSENT_COOKIE);

  if (!consentCookie) {
    return DEFAULT_CONSENT_CATEGORIES;
  }

  try {
    // The OneTrust SDK serializes the cookie value as URL params.
    // eslint-disable-next-line compat/compat
    const parsedConsentCookie = new URLSearchParams(consentCookie);

    const consentGroups = parsedConsentCookie.get('groups');

    if (!consentGroups) {
      return DEFAULT_CONSENT_CATEGORIES;
    }

    // The consent categories are stored in the format 'C0001:1,C0002:0'
    // where '0' means consent NOT given and '1' means consent given.
    const consentCategories = consentGroups
      .split(',')
      .reduce((categories, categoryString) => {
        const [categoryId, categoryState] = categoryString.split(':');
        const category = CONSENT_CATEGORIES[categoryId];
        const isCategoryActive = categoryState === '1';

        if (category && isCategoryActive) {
          categories.push(category);
        }

        return categories;
      }, []);
    return consentCategories;
  } catch (error) {
    return DEFAULT_CONSENT_CATEGORIES;
  }
};

/**
 * Returns whether the cookie consent should be shown for the country
 * determined from the OneTrust geolocation data.
 */
export const isConsentEnabled = () => {
  if (!window.OneTrust) {
    return true;
  }

  const geolocationData = window.OneTrust.getGeolocationData();

  if (geolocationData && geolocationData.country) {
    return COOKIE_CONSENT_COUNTRIES.includes(geolocationData.country);
  }

  return true;
};

let isInitialized = false;
const init = () => {
  // The OneTrust SDK calls the init callback multiple times.
  // This check makes sure it's only executed once.
  if (isInitialized) {
    return;
  }

  isInitialized = true;

  if (!window.OneTrust) {
    return;
  }

  if (i18n.language) {
    changeLanguage(i18n.language);
  } else {
    i18n.on('languageChanged', (language) => {
      changeLanguage(language);
    });
  }

  const initialCategories = getActiveConsentCategories();
  const isEnabled = isConsentEnabled();

  // OneTrust only pushes the `update` consent if the user clicks on the cookie banner. There are no banner on the Non-GDPR (non-cookie-consent) countries.
  // a manual Allowing all cookie consent for non-GDPR countries.
  // ref: https://my.onetrust.com/s/article/UUID-d81787f6-685c-2262-36c3-5f1f3369e2a7?topicId=0TO1Q000000ItVuWAK#UUID-d81787f6-685c-2262-36c3-5f1f3369e2a7_section-idm231981224490148
  if (!isEnabled) {
    window.OneTrust.AllowAll();
  }

  cookieConsentEvents.emit('enable', isEnabled);
  cookieConsentEvents.emit('init', { categories: initialCategories });

  window.OneTrust.OnConsentChanged((event) => {
    const categoryIds = event.detail;
    const categories = categoryIds.map((id) => CONSENT_CATEGORIES[id]);

    cookieConsentEvents.emit('change', { categories });
  });
};

if (window.OneTrust) {
  // The OneTrust SDK has already loaded and is available.
  init();
} else {
  // The OneTrust SDK hasn't loaded yet and will call this callback when ready
  // (see OptanonWrapper in index.html).
  window[env('COOKIE_CONSENT_INIT')] = init;
}
