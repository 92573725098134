import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { light } from '@sumup/design-tokens';
import { ToastProvider } from '@sumup/circuit-ui';

import './app.css';
import './polyfills';
import 'utils/i18n';
import 'utils/cookie-consent';
import Layout from 'components/Layout';
import Routes from 'components/Routes';

const App = () => (
  <ThemeProvider theme={light}>
    <ToastProvider>
      <Layout>
        <Routes />
      </Layout>
    </ToastProvider>
  </ThemeProvider>
);

export default App;
